/* eslint-disable jsx-a11y/alt-text */
import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { Image } from '@/components/common/image';
import { WistiaVideoPlayer } from '@/components/common/WistiaComponents/WistiaVideoPlayer';

import type { HotspotImage, WistiaPopover } from '@/types/shared';

export interface SingleImageProps {
  _type?: 'singleImage';
  desktopImage: HotspotImage;
  mobileImage?: HotspotImage;
  alternateText: string;
  isDecorativeImage?: boolean;
  wistiaPopover?: WistiaPopover;
}

interface ImageOrVideoProps {
  wistiaPopover: WistiaPopover | undefined;
  image: HotspotImage;
  alternateText?: string;
  isDecorativeImage?: boolean;
}

const ImageVideoSwitcher = ({
  image,
  wistiaPopover,
  alternateText,
  isDecorativeImage,
}: ImageOrVideoProps) => {
  if (wistiaPopover?.videoId) {
    return (
      <WistiaVideoPlayer
        image={{ _type: 'image', alternateText, asset: image?.asset }}
        wistiaPopover={wistiaPopover}
      />
    );
  }

  if (image?.asset) {
    return (
      <Image
        asset={image?.asset}
        alternateText={alternateText}
        isDecorativeImage={isDecorativeImage}
        preserveMaxDimensions
        radius="l"
        style={{ objectFit: 'contain' }}
      />
    );
  }
};

export const SingleImage = ({
  desktopImage,
  mobileImage,
  alternateText,
  isDecorativeImage,
  wistiaPopover,
}: SingleImageProps) => {
  if (mobileImage?.asset) {
    return (
      <>
        <DesktopVisible>
          <ImageVideoSwitcher
            image={desktopImage}
            alternateText={alternateText}
            isDecorativeImage={isDecorativeImage}
            wistiaPopover={wistiaPopover}
          />
        </DesktopVisible>
        <MobileVisible>
          <ImageVideoSwitcher
            image={mobileImage}
            alternateText={alternateText}
            isDecorativeImage={isDecorativeImage}
            wistiaPopover={wistiaPopover}
          />
        </MobileVisible>
      </>
    );
  }

  return (
    <ImageVideoSwitcher
      image={desktopImage}
      alternateText={alternateText}
      isDecorativeImage={isDecorativeImage}
      wistiaPopover={wistiaPopover}
    />
  );
};

const DesktopVisible = styled.div`
  display: none;
  ${screen.md} {
    display: initial;
  }
`;

const MobileVisible = styled.div`
  ${screen.md} {
    display: none;
  }
`;
