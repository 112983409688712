import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { HeadingLevel } from '@/components/common/MarkUp';
import { bodyLarge } from '@/styles/typography';

import type { BlockWithAudience } from '@/types/shared';
import type { SanityBlock, SanityKeyed } from 'sanity-codegen';

export interface HeadingTextProps extends BlockWithAudience {
  _type?: 'headingText';
  columnHeading?: Array<SanityKeyed<SanityBlock>>;
  body: Array<SanityKeyed<SanityBlock>>;
  verticalAlignDesktop?: 'start' | 'center' | 'end';
  verticalAlignMobile?: 'start' | 'center' | 'end';
  desktopAlignment?: 'start' | 'center';
  mobileAlignment?: 'start' | 'center';
}

const HeadingText = ({
  columnHeading,
  body,
  verticalAlignDesktop = 'start',
  verticalAlignMobile = 'start',
  pageAudience,
  isAudienceSwitcherEnabled,
  desktopAlignment = 'center',
  mobileAlignment = 'center',
}: HeadingTextProps) => {
  return (
    <ColumnWrapper
      verticalAlignDesktop={verticalAlignDesktop}
      verticalAlignMobile={verticalAlignMobile}
    >
      <HeadingLevel>
        <StyledCaptionHeadingDescription
          heading={columnHeading}
          description={body}
          padding={{ description: 'xxx-small', descriptionMobile: 'xxx-small' }}
          defaultHeadingSize="heading-md"
          desktopAlignment={desktopAlignment}
          mobileAlignment={mobileAlignment}
          pageAudience={pageAudience}
          isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
        />
      </HeadingLevel>
    </ColumnWrapper>
  );
};
const ColumnWrapper = styled.div<{
  verticalAlignDesktop: 'start' | 'center' | 'end';
  verticalAlignMobile: 'start' | 'center' | 'end';
}>`
  text-align: ${({ verticalAlignMobile }) => `${verticalAlignMobile}`};
  align-content: ${({ verticalAlignMobile }) => `${verticalAlignMobile}`};
  padding-top: var(--spacing-300);

  ${screen.sm} {
    text-align: ${({ verticalAlignDesktop }) => `${verticalAlignDesktop}`};
    align-content: ${({ verticalAlignDesktop }) => `${verticalAlignDesktop}`};
    padding-top: 0;
    &:first-of-type {
      padding-top: 0;
    }
  }
`;

const StyledCaptionHeadingDescription = styled(CaptionHeadingDescription)`
  .description li {
    ${bodyLarge};
  }
`;

export { HeadingText };
