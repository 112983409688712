import styled from '@emotion/styled';

import { Chart } from '@/components/blocks/charts';
import { ExplainerCard } from '@/components/blocks/ExplainerCard';
import { ExtendedImageStat } from '@/components/blocks/MultiColumnLayout/ExtendedImageStat';
import { HeadingText } from '@/components/blocks/MultiColumnLayout/HeadingText';
import { SingleImage } from '@/components/blocks/MultiColumnLayout/SingleImage';
import { SuccessStoryCard } from '@/components/blocks/SuccessStoryCard';
import { Badge } from '@/components/common/Badge';
import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { CaptionHeadingDescription } from '@/components/common/CaptionHeadingDescription';
import { HeadingLevel } from '@/components/common/MarkUp';
import { Paragraph as ParagraphColumn } from '@/components/common/Paragraph/Paragraph';
import { ConditionalAnimateWrapper } from '@/components/common/utils';
import { Section } from '@/components/layout/Section';
import { getButtonComponentThemeSolid } from '@/lib/colourUtils';
import { useMediaQuery } from '@/lib/useMediaQuery';
import { blockUrlWithAudience } from '@/lib/utils';

import type { ChartsProps } from '@/components/blocks/charts';
import type { ExplainerCardProps } from '@/components/blocks/ExplainerCard';
import type { ExtendedImageStatProps } from '@/components/blocks/MultiColumnLayout/ExtendedImageStat';
import type { HeadingTextProps } from '@/components/blocks/MultiColumnLayout/HeadingText';
import type { SingleImageProps } from '@/components/blocks/MultiColumnLayout/SingleImage';
import type { SuccessStoryCardProps } from '@/components/blocks/SuccessStoryCard';
import type { ParagraphProps } from '@/components/common/Paragraph/Paragraph';
import type {
  SanityAudienceType,
  SanityKeyed,
  SanityMultiColumnLayoutType,
} from '@/types/sanity';
import type { BlockWithAudience, CTAProps } from '@/types/shared';

export interface MultiColumnLayoutProps
  extends Omit<SanityMultiColumnLayoutType, 'columns' | 'ctaButton'>,
    BlockWithAudience {
  columns: Array<
    | SanityKeyed<ExtendedImageStatProps>
    | SanityKeyed<HeadingTextProps>
    | SanityKeyed<SuccessStoryCardProps>
    | SanityKeyed<ParagraphProps>
    | SanityKeyed<ExplainerCardProps>
    | SanityKeyed<ChartsProps>
    | SanityKeyed<SingleImageProps>
  >;
  ctaButton?: CTAProps & {
    _type: 'ctaButton';
    audienceRef?: SanityAudienceType;
    noAudienceToggle?: boolean;
  };
}

type AlignmentProps = 'start' | 'center';

export const MultiColumnLayout = ({
  tag,
  caption,
  heading,
  description,
  headingAlignDesktop,
  headingAlignMobile,
  contentAlignDesktop,
  contentAlignMobile,
  ctaAlignDesktop = 'center',
  ctaAlignMobile = 'center',
  columns,
  ctaButton,
  backgroundColour,
  headingWidth,
  animate,
  isCaptionUppercase = false,
  paddingAdjustment,
  blockSpacing,
  pageAudience,
  isAudienceSwitcherEnabled,
  reverseColumnOrder,
}: MultiColumnLayoutProps) => {
  const backgroundColourToken = backgroundColour?.token;

  const isDesktop = useMediaQuery(screen.mdQuery);

  const conditionalBreakpointTypes = [
    'successStoryCard',
    'explainerCard',
    'chartsGraphs',
  ];

  const conditionalBreakpoint = columns.some((column) =>
    conditionalBreakpointTypes.includes(column._type),
  )
    ? screen.md
    : screen.sm;

  const reverseOrderMobile =
    reverseColumnOrder ||
    (!isDesktop &&
      columns[0]?._type === 'chartsGraphs' &&
      columns[1]?._type !== 'chartsGraphs');

  const conditionalSecondRowImage =
    columns[0]?._type === 'paragraph' &&
    columns[1]?._type === 'paragraph' &&
    columns[2]?._type === 'singleImage' &&
    !isDesktop;

  return (
    <Section
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
      backgroundColour={backgroundColourToken}
      className="multi-column-layout"
    >
      <HeadingLevel>
        <Container>
          <ConditionalAnimateWrapper animate={animate}>
            <HeadingContainer
              className={`heading-${columns[0]._type}`}
              breakpoint={conditionalBreakpoint}
              headingAlignDesktop={headingAlignDesktop}
              headingAlignMobile={headingAlignMobile}
              headingWidth={headingWidth}
            >
              {tag?.tagText && (
                <Badge bgColour={tag?.tagColour}>{tag.tagText}</Badge>
              )}
              {(caption || heading || description) && (
                <StyledCaptionHeadingDescription
                  caption={caption}
                  heading={heading}
                  description={description}
                  desktopAlignment={headingAlignDesktop}
                  mobileAlignment={headingAlignMobile}
                  isCaptionUppercase={isCaptionUppercase}
                  pageAudience={pageAudience}
                  isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
                />
              )}
            </HeadingContainer>
          </ConditionalAnimateWrapper>
          <ConditionalAnimateWrapper animate={animate}>
            <Columns
              breakpoint={conditionalBreakpoint}
              desktopAlignment={contentAlignDesktop}
              mobileAlignment={contentAlignMobile}
              className={columns[0]._type}
              reverseOrderMobile={reverseOrderMobile}
            >
              {columns.map((column) => {
                switch (column._type) {
                  case 'extendedImageStat':
                    return (
                      <ExtendedImageStat
                        key={column._key}
                        {...column}
                        pageAudience={pageAudience}
                        isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
                      />
                    );
                  case 'headingText':
                    return (
                      <HeadingText
                        key={column._key}
                        {...column}
                        desktopAlignment={contentAlignDesktop}
                        mobileAlignment={contentAlignMobile}
                        pageAudience={pageAudience}
                        isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
                      />
                    );
                  case 'successStoryCard':
                    return (
                      <SuccessStoryCard
                        key={column._key}
                        {...(column as SuccessStoryCardProps)}
                        pageAudience={pageAudience}
                        isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
                      />
                    );
                  case 'paragraph':
                    return (
                      <ParagraphColumn
                        key={column._key}
                        {...column}
                        pageAudience={pageAudience}
                        isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
                      />
                    );
                  case 'singleImage':
                    if (!conditionalSecondRowImage) {
                      return <SingleImage key={column?._key} {...column} />;
                    }
                    break;
                  case 'explainerCard':
                    return (
                      <ExplainerCard
                        key={column._key}
                        {...(column as ExplainerCardProps)}
                        pageAudience={pageAudience}
                        isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
                      />
                    );
                  case 'chartsGraphs':
                    return (
                      <ChartContainer>
                        <Chart
                          key={column._key}
                          {...(column as ChartsProps)}
                          verticalPadding={false}
                          backgroundColour={backgroundColour}
                        />
                      </ChartContainer>
                    );
                  default:
                    return null;
                }
              })}
            </Columns>
            {conditionalSecondRowImage && (
              <SecondRow>
                {columns[2]._type === 'singleImage' && (
                  <SingleImage {...columns[2]} />
                )}
              </SecondRow>
            )}
          </ConditionalAnimateWrapper>

          {ctaButton?.buttonLink ? (
            <CtaContainer
              breakpoint={conditionalBreakpoint}
              desktopAlignment={ctaAlignDesktop}
              mobileAlignment={ctaAlignMobile}
            >
              <CtaButton
                breakpoint={conditionalBreakpoint}
                href={blockUrlWithAudience(
                  ctaButton.buttonLink,
                  ctaButton.audienceRef,
                  pageAudience,
                  ctaButton.noAudienceToggle,
                  isAudienceSwitcherEnabled,
                )}
                aria-label={ctaButton.buttonAccessibleLabel}
                iconPosition="right"
                variant={getButtonComponentThemeSolid(backgroundColourToken)}
                className={
                  ctaButton.trackingTag?.blockName &&
                  `tracking-${ctaButton.trackingTag.blockName}__${ctaButton.trackingTag.blockAction}`
                }
              >
                {ctaButton.buttonText}
              </CtaButton>
            </CtaContainer>
          ) : null}
        </Container>
      </HeadingLevel>
    </Section>
  );
};

type ConditionalBreakpointType = {
  breakpoint: string;
};

const HeadingContainer = styled.div<
  {
    headingAlignMobile: AlignmentProps;
    headingAlignDesktop: AlignmentProps;
    headingWidth: string;
  } & ConditionalBreakpointType
>`
  text-align: ${(props) => props.headingAlignMobile};
  width: 100%;

  ${(props) => props.breakpoint} {
    text-align: ${(props) => props.headingAlignDesktop};
  }

  ${screen.md} {
    h2 {
      margin: ${() => (props) =>
        props.headingAlignDesktop === 'start' ? '0 auto 0 0' : '0 auto'};
      max-width: ${(props) => props.headingWidth};
    }
  }
`;

const Columns = styled.div<
  {
    desktopAlignment: AlignmentProps;
    mobileAlignment: AlignmentProps;
    reverseOrderMobile?: boolean;
  } & ConditionalBreakpointType
>`
  align-self: center;
  display: flex;
  flex-direction: ${({ reverseOrderMobile }) =>
    reverseOrderMobile ? 'column-reverse' : 'column'};
  gap: var(--spacing-250);
  width: 100%;

  > div,
  > a {
    text-align: ${({ mobileAlignment }) => `${mobileAlignment}`};
    justify-items: ${({ mobileAlignment }) => `${mobileAlignment}`};
  }

  ${(props) => props.breakpoint} {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: var(--spacing-300, 24px);

    > div,
    > a {
      text-align: ${({ desktopAlignment }) => `${desktopAlignment}`};
      justify-content: ${({ desktopAlignment }) => `${desktopAlignment}`};
    }
  }

  &.successStoryCard {
    gap: var(--spacing-250);
  }

  &.paragraph,
  &.image {
    gap: var(--spacing-300);
  }

  &.explainerCard {
    gap: var(--spacing-small);
  }

  &.chartsGraphs {
    > section > div {
      padding: 0;
    }
  }
  ${screen.sm} {
    &.paragraph,
    &.image {
      gap: var(--spacing-400);
    }

    &.explainerCard {
      gap: var(--spacing-300);
    }
  }

  ${screen.md} {
    gap: var(--spacing-400, 32px);
    &.successStoryCard {
      gap: var(--spacing-300);
    }
    &.paragraph,
    &.image {
      gap: var(--spacing-500);
    }
  }

  ${screen.lg} {
    gap: var(--spacing-500, 40px);
  }
`;

const CtaButton = styled(Button)<ConditionalBreakpointType>`
  margin-top: var(--spacing-400, 32px);

  ${(props) => props.breakpoint} {
    margin-top: var(--spacing-500, 40px);
  }
`;

const CtaContainer = styled.div<
  {
    desktopAlignment: AlignmentProps;
    mobileAlignment: AlignmentProps;
  } & ConditionalBreakpointType
>`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: ${({ mobileAlignment }) => `${mobileAlignment}`};
  text-align: ${({ mobileAlignment }) => `${mobileAlignment}`};
  justify-items: ${({ mobileAlignment }) => `${mobileAlignment}`};

  ${(props) => props.breakpoint} {
    align-items: ${({ desktopAlignment }) => `${desktopAlignment}`};
    text-align: ${({ desktopAlignment }) => `${desktopAlignment}`};
    justify-items: ${({ desktopAlignment }) => `${desktopAlignment}`};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChartContainer = styled.div`
  > section {
    border-radius: var(--radius-m);
    height: 100%;
  }
`;

const SecondRow = styled.div`
  margin-top: var(--spacing-large);
  ${screen.sm} {
    margin-top: var(--spacing-x-large);
  }
`;

const StyledCaptionHeadingDescription = styled(CaptionHeadingDescription)`
  margin-bottom: var(--spacing-x-large);
`;
